<template>
    <div class="card p-2 card-stretch">
      <div class="card-container">
    <a-tabs >
      <a-tab-pane key="1" tab="Profile">
         <div v-if="userProfile" class="row">
            <div class="col-4">
                <div class="card">
                    <div class="card-inner">
                        <div class="d-flex flex-wrap flex-column align-items-center">
                        <div class="  mb-3" style="position:relative;">
                          <div style="position: absolute;right: 5px;top: 5px; cursor:pointer;">
                            
                            <a-popconfirm
                            v-if="$store.state.userSettings.avatarURI"
                              title="Are you sure you want to remove your profile picture?"
                              ok-text="Yes"
                              cancel-text="No"
                              @confirm="confirmAvatarReset"
                              @cancel="cancelAvatarReset"
                            >
                              <em class="icon ni ni-delete"></em>
                            </a-popconfirm>
                          </div>
                            <avatar :src="$store.state.userSettings.avatarURI" :size="200" :rounded="false" v-if="$store.state.userSettings.firstName" :username="$store.state.userSettings.firstName + ' ' + $store.state.userSettings.lastName" />
                        </div>
                        <div class="text-center">
                            <div class="text-dark font-weight-bold font-size-18">{{$store.state.userSettings.firstName + ' ' + $store.state.userSettings.lastName}}</div>
                            <div class="text-uppercase font-size-12 mb-3">{{$store.state.userSettings.primaryRole}}</div>
                            <button id="pick-avatar" class="btn btn-secondary btn-sm btn-addon">
                            <span class="btn-addon">
                                <em class="icon ni ni-upload"></em>
                            </span>
                            Upload Avatar
                            </button>
                               <avatar-cropper
                                    trigger="#pick-avatar"
                                    :labels="{ submit: 'Confirm', cancel: 'Cancel' }"
                                    :upload-handler="cropperHandler"
                                    />
                        </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-8">
                <div class="card ">
                    <div class="card-inner">
                        <h5>Personal Details</h5>
                        <a-form>
                            <a-form-item
                            label="Email Address"
                            :label-col="{ span: 6 }"
                            :wrapper-col="{ span: 12 }"
                            >
                            <a-input v-model="$store.state.userSettings.email" />
                            </a-form-item>
                            <a-form-item
                            label="Full Name"
                            :label-col="{ span: 6 }"
                            :wrapper-col="{ span: 12 }"
                            >
                            <a-input v-model="$store.state.userSettings.firstName" />
                            </a-form-item>
                            <a-form-item
                            label="Preferred Name"
                            :label-col="{ span: 6 }"
                            :wrapper-col="{ span: 12 }"
                            >
                            <a-input v-model="$store.state.userSettings.lastName" />
                            </a-form-item>
                            <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
                            <button v-on:click="updateUserSettings()"  class="btn btn-success btn-sm float-right">
                                <em class="icon ni ni-save-fill mr-2"></em>Save
                            </button>
                            </a-form-item>
                        </a-form>
                    </div>
                </div>
                <hr class="preview-hr">
                <div class="card">
                    <div class="card-inner">
                        <h5>Security Settings</h5>
                        <a-form>
                            <a-form-item
                            label="Current Password"
                            :label-col="{ span: 6 }"
                            :wrapper-col="{ span: 12 }"
                            >
                            <a-input v-model="newPassword.OldPassword"  type="password"  placeholder="Current Password" />
                            </a-form-item>
                            <a-form-item>
                              <a-alert message="Please ensure your password has a high security rating when setting a new one." banner />
                            </a-form-item>
                            <a-form-item
                            label="New Password"
                            :label-col="{ span: 6 }"
                            :wrapper-col="{ span: 12 }"
                            >
                            <password
                              v-model="newPassword.NewPassword"
                              :toggle="true"
                              @score="showScore"
                              @feedback="showFeedback"
                            />
                            </a-form-item>
                            <a-form-item :wrapper-col="{ span: 14, offset: 4 }">
                            <button  v-on:click="updatePassword()"  class="btn btn-success btn-sm float-right">
                                <em class="icon ni ni-unlock mr-2"></em> Change Password
                            </button>
                            </a-form-item>
                        </a-form>
                    </div>
                </div>
            </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="2" tab="Holidays">
        <div class="row">
          <div class="col-12">
            <holidaysummarycharts></holidaysummarycharts>
            <userholidays></userholidays>
          </div>
        </div>
      </a-tab-pane>
      <a-tab-pane key="3" tab="Job Details">
        <jobDetails/>
      </a-tab-pane>
      <a-tab-pane key="4" tab="Platform Settings">
        <p>User Settings To go here</p>
      </a-tab-pane>
    </a-tabs>
  </div>
    </div>
</template>
<script>
import AvatarCropper from 'vue-avatar-cropper'
import store from '@/store'
import Password from 'vue-password-strength-meter'
import userholidays from '@/components/holiday/userholidays'
import holidaysummarycharts from '@/components/holiday/holidaysummarycharts'
import jobDetails from '@/components/my/profile/jobDetails.vue'

export default {
  components: { AvatarCropper, Password, userholidays, holidaysummarycharts, jobDetails },
  data() {
    return {
      pwSuggestions: null,
      pwWarning: null,
      message: 'ready',
      userProfile: null,
      newPassword: { oldPassword: null, newPassword: null },
      // user: {
      //   id: 1,
      //   fullName: 'Neil Hewitt',
      //   preferredName: 'Neil',
      //   emailAddress: 'Neil@ravenware.co.uk',
      //   avatar: 'https://ravenware.co.uk/avatar.png',
      // },
    }
  },
  created () {
    // this.getView()
    if (this.$route.query.forceReload != null) {
      window.location.href = window.location.origin + window.location.pathname;
      //this.$router.push('/my/profile')
      //const query = Object.assign({}, this.$route.query);
      //delete query.my_param;
      //this.$route.replace({ query })
      //this.$router.go()
    }
    this.get_UserProfile()
  },
  methods: {
    // getView (){ this.$http.get('/ViewAccess/myprofile')
    //     .then(() => { 
    //     })
    //     .catch(() => { 
    //     })},
    updateUserSettings () {
      let usrSettings = {
        firstName: store.state.userSettings.firstName,
        email: store.state.userSettings.email,
        lastName: store.state.userSettings.lastName,
      }
      this.$http.post('/users/Update_UserSettings/', usrSettings)
        .then(() => {
          this.$message.success('Settings Updated')
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    showFeedback ({ suggestions, warning }) {
      this.pwSuggestions = suggestions
      this.pwWarning = warning
    },
    showScore (score) {
      console.log('💯', score)
    },
    updatePassword () {
      this.$http.post('/account/ChangePassword/', this.newPassword)
        .then((response) => {
          if (response.data.success === true || response.data.success === 'true') {
            this.$message.success('Password Updated')
          } else {
            if (response.data.errors[0].errorMessage != null) {
              this.$message.error(response.data.errors[0].errorMessage)
            } else {
              this.$message.error('Password update failed. Please ensure your password is complex')
            }
          }
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    confirmAvatarReset () {
      this.$http.get('/users/Remove_ProfileImage/')
        .then(() => {
          this.$message.success('Avatar Removed')
          this.refreshUserSettings()
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    cancelAvatarReset () {
      this.$message.error('Cancelled')
    },
    cropperHandler (cropper) {
      let avatar = cropper.getCroppedCanvas({ width: 100, height: 100 }).toDataURL(this.cropperOutputMime)
      let imgUpload = { image: avatar }
      this.$http.post('/users/Upload_ProfileImage/', imgUpload)
        .then((response) => {
          this.userProfile.user.avatar = response.data.fileUri
          this.$message.success('Avatar updated')
          this.refreshUserSettings()
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    get_UserProfile () {
      this.$http.get('/users/Get_UserProfile/')
        .then((response) => {
          this.userProfile = response.data
        })
        .catch(() => {
          this.$message.error('There has been an error')
        })
    },
    refreshUserSettings () {
      this.$http.get('/users/Get_UserSettings/')
        .then((response) => {
          store.commit('updateUserSettings', response.data)
        })
        .catch((error) => {
          alert(error)
        })
    },
  },
}
</script>
