<template>
  <div class="card-inner">
      <div v-if="employeeJobDetails">
        <a-form-model ref="user" :model="employeeJobDetails">
          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Location</label>
            </div>
            <div class="col-4">
               <a-input class="invisibox" disabled v-model="employeeJobDetails.location" />
            </div>
          </div>                   

          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Department</label>
            </div>
            <div class="col-4">
              <a-select class="invisibox" disabled v-model="employeeJobDetails.departmentId">
                  <a-select-option key="1">
                    Test
                  </a-select-option>
                </a-select>
            </div>
          </div>     
                                        
          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Job Role</label>
            </div>
            <div class="col-4">
              <a-input class="invisibox" disabled v-model="employeeJobDetails.jobRole" />
            </div>
          </div>

          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Reports To</label>
            </div>
            <div class="col-4">
              <a-input class="invisibox" disabled v-model="employeeJobDetails.reportsToId" />
            </div>
          </div>

          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Start Date</label>
            </div>
            <div class="col-4">
              <a-date-picker class="invisibox" disabled v-model="employeeJobDetails.startDate" />
            </div>
          </div>

          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">NI / SSN Number</label>
            </div>
            <div class="col-4">
              <a-input class="invisibox" disabled v-model="employeeJobDetails.niNumberOrSSN" />
            </div>
          </div>

          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Holiday Year</label>
            </div>
            <div class="col-4">
              <a-input class="invisibox" disabled v-model="employeeJobDetails.holidayYearId" />
            </div>
          </div>

          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Work Pattern</label>
            </div>
            <div class="col-4">
              <a-input class="invisibox" disabled v-model="employeeJobDetails.workPatternId" />
            </div>
          </div>

          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Salary Period</label>
            </div>
            <div class="col-4">
               <a-input class="invisibox" disabled v-model="employeeJobDetails.salaryPeriodId" />
            </div>
          </div>

          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Salary Amount</label>
            </div>
            <div class="col-4">
              <a-input class="invisibox" disabled v-model="employeeJobDetails.salaryAmount" />
            </div>
          </div>

          <div class="row">
            <div class="col-5">
              <label class="form-label float-right">Salary Currency</label>
            </div>
            <div class="col-4">
              <a-input class="invisibox" disabled v-model="employeeJobDetails.salaryCurrencyId" />
            </div>
          </div>                                                    
        </a-form-model>
    </div>
  </div>
</template>

<script>
export default {
    data() {
        return {
            employeeJobDetails: null,
        }
    },
    created() {
        this.getEmployeeJobDetails()
    },
    methods: {
        getEmployeeJobDetails () {
            this.$http.get('/users/get_MyJobDetails/')
            .then((response) => {
              this.employeeJobDetails = response.data
            })
            .catch(() => {
              this.$message.error('There has been an error')
            })
        },
    }
}
</script>

<style>

</style>